.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #18181800;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #18181800;
  /* border-radius: 5%; */
  /* box-shadow: 0 0 1vmin 5vmin #18181800; */
}

.Main-title {
  font-family: 'Platypi', serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #bdbdc2;
}

.nav-header {
  display: inline;
  font-family: 'Oswald', sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  cursor: pointer;
  text-decoration: none;
  color: #bdbdc2;
  fill: #bdbdc2;
  padding: 0px 10px;
  margin: 0;
}

.nav-header:hover,
.nav-header-virt-hover {
  color: #f0f2a6;
  fill: #f0f2a6;
  background-color: #181818;
  border: 1px solid #f0f2a6;
  border-radius: 1vmin 1vmin 0 0;
  padding: 0px 9px;
  border-bottom-color: #181818;
  /* for safari */
  border-top-color: #f0f2a6;
  z-index: 0;
}

.fake-nav-header:hover {
  background-color: transparent;
  border: transparent;
  padding: 0px 10px;
}

.sections {
  font-size: 1.5em;
}

.nav-menu-item {
  display: inline-block;
  font-family: "Dosis", sans-serif;
  font-size: 0.7em;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  cursor: pointer;
  text-decoration: none;
  color: #bdbdc2;
  fill: #bdbdc2;
  margin: 5px 10px;
}

.nav-menu-item:hover {
  color: #ee8fff;
  fill: #ee8fff;
}